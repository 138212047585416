import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Clients = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(
        relativePath: { eq: "glance-client-logos-mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(
        relativePath: { eq: "glance-client-logos-desktop.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // This art directed image approach can't be used until 
  // the aspect ratio bug is fixed in Gatsby:
  // const sources = [
  //   data.mobileImage.childImageSharp.fluid,
  //   {
  //     ...data.desktopImage.childImageSharp.fluid,
  //     media: `(min-width: 1024px)`,
  //   },
  // ]

  return (
    <div className="art-directed-img">
      <div className="m">
        <Img fluid={data.mobileImage.childImageSharp.fluid} />
      </div>
      <div className="d">
        <Img fluid={data.desktopImage.childImageSharp.fluid} />
      </div>
    </div>
  )
}

export default Clients
