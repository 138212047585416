import React from "react"
import Swiper from "react-id-swiper"
import "../../node_modules/swiper/swiper.scss"

const Testimonials = () => {
  const params = {
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
      dynamicBullets: true,
      // dynamicMainBullets: 5,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  return (
    <Swiper {...params}>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Glance is a great dev partner because they ‘just get it’.
                  They don’t require hand-holding, and this enables them to
                  provide what’s likely the best ROI in the market. Unlike many
                  other development partners I’ve worked with, Glance not only
                  delivers, but they deliver the first time, on-time.”
                </p>
                <cite>
                  Tyson Miller, Intel, Sr. Director of Product&nbsp;Management
                </cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Lance is a magician, a legend and an all-around great
                  developer. Our agency has worked with Glance Digital on
                  countless web projects over the years. He has an easy-going
                  approach to dev, and executes every project with skill, grace
                  and&nbsp;clarity.”
                </p>
                <cite>
                  Shannon Cherry, Will Creative, Production&nbsp;Manager
                </cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Glance consistently adds that little extra to a site that
                  elevates it from great to excellent. They are a fantastic
                  collaborator with profound expertise, a passion for great
                  design and an addictively cheerful&nbsp;attitude.”
                </p>
                <cite>Christina Lauer, Today Studio, Partner/Designer</cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Working with Lance on our new website was an absolute joy –
                  he’s just got such a positive vibe. His attention to detail
                  and strong understanding of good, clean, flowing digital
                  design made for a site that we’re very proud&nbsp;of.”
                </p>
                <cite>Robert Jackson, Fast + Epp, Associate</cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “From the very first delivery it was clear that Glance had
                  both the technical expertise and eye for design we needed.
                  Having now worked with them for over a year to complete the
                  project, I am pleased to say that their ability to continually
                  provide deliverables that both our design and development
                  teams are happy with makes them our absolute first choice for
                  ongoing&nbsp;work.”
                </p>
                <cite>
                  James Ritchie, Split a Bit, Operations &
                  Business&nbsp;Development
                </cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Glance is a breath of fresh air! I've worked with Glance on
                  various projects from new website builds, large enhancements,
                  consulting or support in fixing minor website bugs. No matter
                  the job, Glance is always there to support and consistently
                  delivers high quality work on time and on budget. I would
                  recommend Glance to any client or customer.“
                </p>
                <cite>
                  Courtney Beck, Cactus Club Cafe, Project Coordinator,
                  IT&nbsp;Applications
                </cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Glance is an incredible dev partner who appreciates and
                  understands our design direction. His technical insights add
                  value to our process and I trust him to accurately interpret
                  our designs. The Glance team is very easy to collaborate with
                  and they are committed to working with our designers to get
                  the final product&nbsp;right.”
                </p>
                <cite>
                  Justin Tan, Turtle Design Inc, Design Director, Co-founder
                </cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Of the many development partners I have worked with over the
                  years, Glance is by far and away the best. Their design
                  background enables them to collaborate with the Combo team
                  during the design process, and we know that what we design
                  will get built beautifully. On top of that, Glance is easy to
                  communicate&nbsp;with.”
                </p>
                <cite>
                  Su T Fitterman, Combo Creative, Creative&nbsp;Director
                </cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Lance is my secret weapon. His work is really high quality
                  and he has a keen eye for detail. He is extremely articulate
                  and enjoys helping me solve complex visual and functional
                  conundrums. I wish I had a lance in every aspect of
                  my&nbsp;business.”
                </p>
                <cite>Chris Neary, Frank Strategy, Brand&nbsp;Strategist</cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “Glance has a great ability to process complex information and
                  transform it into solid, solution-based UX and&nbsp;UI.”
                </p>
                <cite>Dennis Boyle, Boyle&nbsp;Design&nbsp;Corp.</cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10">
            <article itemScope itemType="http://schema.org/WebPageElement">
              <blockquote>
                <p>
                  “As much as we'd like to keep Glance Digital a secret, the
                  crew is too great not to tell the world. Glance gets creative,
                  embraces the unknown and gets down&nbsp;to&nbsp;work.”
                </p>
                <cite>
                  Drew Pautler, Good Fortune Collective, Founder /
                  Creative&nbsp;Director
                </cite>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
    </Swiper>
  )
}

export default Testimonials
