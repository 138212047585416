import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Lance from "../images/lance-cummings.svg"
import Clients from "../components/clients"
import Testimonials from "../components/testimonials"

import IntroModule from "../styles/intro.module.scss"
import ExperienceModule from "../styles/experience.module.scss"
import TestimonialsModule from "../styles/testimonials.module.scss"
import ServicesModule from "../styles/services.module.scss"
import ParallaxClipPath from "../components/parallaxClipPath"
import ParallaxElement from "../components/parallaxElement"

const IndexPage = () => {
  
  return (
    <Layout>
      <SEO title="Your Technical Partner for Website Development" />

      <section className={IntroModule.container}>
        <ParallaxElement className="parallax">
          <div className="grid-container grid-x grid-padding-x">
            <div className="cell small-12">
              <article itemScope itemType="http://schema.org/WebPageElement">
                <div className={IntroModule.right}>
                  <h1>
                    Your Technical Partner <small>(who gets you)</small>
                  </h1>
                </div>
                <div className={IntroModule.left}>
                  <img
                    src={Lance}
                    alt="Lance Cummings"
                    width="217"
                    height="667"
                  />
                </div>
              </article>
            </div>
          </div>
        </ParallaxElement>
      </section>

      <section className={ExperienceModule.container}>
        <ParallaxClipPath className={ExperienceModule.panel}>
          <div className="grid-container grid-x grid-padding-x align-center">
            <div className="cell small-12 medium-8">
              <article itemScope itemType="http://schema.org/WebPageElement">
                <h2>
                  20 years of experience designing and building for
                  the&nbsp;internet
                </h2>
                <p>
                  We love helping those we partner with translate their creative
                  vision into reality. Whether you come with complete design
                  mocks or just an idea, we bring flawless execution to the
                  table.
                </p>
              </article>
            </div>
          </div>
          <div className="grid-x grid-padding-x align-center">
            <div className="cell small-12">
              <aside className={ExperienceModule.clients}>
                <Clients />
              </aside>
            </div>
          </div>
        </ParallaxClipPath>
      </section>

      <section className={TestimonialsModule.container}>
        <ParallaxClipPath
          className={TestimonialsModule.panel}
          xPerc={16}
          inertia={0.007}
        >
          <i className={TestimonialsModule.stars}></i>
          <Testimonials />
        </ParallaxClipPath>
      </section>

      <section className={ServicesModule.container}>
        <ParallaxClipPath
          className={ServicesModule.panel}
          xPerc={62.5}
          inertia={0.02}
          direction="left"
          inverted="true"
        >
          <ParallaxElement className="parallax">
            <div className="grid-container grid-x grid-padding-x align-center">
              <div className="cell small-12 medium-8">
                <article itemScope itemType="http://schema.org/WebPageElement">
                  <i className={ServicesModule.logo}></i>

                  <h2>Glance has equal parts passion for art and technology</h2>
                  <p>
                    We started our journey in the fields of furniture making and
                    book design, before finding our home in making digital
                    spaces feel tangible.
                  </p>
                </article>
              </div>
            </div>
            <div className="grid-container grid-x grid-padding-x align-center">
              <div className="cell small-12 large-3">
                <i className={ServicesModule.ux}></i>
                <h6 className={ServicesModule.listHeading}>
                  User Experience Design
                </h6>
                <ul className={ServicesModule.list}>
                  <li>Experience strategy</li>
                  <li>Wireframes</li>
                  <li>Interface design</li>
                  <li>A/B testing</li>
                </ul>
              </div>
              <div className="cell small-12 large-3">
                <i className={ServicesModule.fe}></i>
                <h6 className={ServicesModule.listHeading}>
                  Front End Development
                </h6>
                <ul className={ServicesModule.list}>
                  <li>JavaScript</li>
                  <li>SCSS</li>
                  <li>HTML templates</li>
                  <li>React components</li>
                </ul>
              </div>
              <div className="cell small-12 large-3">
                <i className={ServicesModule.fs}></i>
                <h6 className={ServicesModule.listHeading}>
                  Full Stack Development
                </h6>
                <ul className={ServicesModule.list}>
                  <li>Progressive Web Apps</li>
                  <li>Custom solutions</li>
                  <li>Tech support</li>
                </ul>
              </div>
              <div className="cell small-12 large-3">
                <i className={ServicesModule.wp}></i>
                <h6 className={ServicesModule.listHeading}>
                  Content Management
                </h6>
                <ul className={ServicesModule.list}>
                  <li>Bespoke websites built on WordPress</li>
                  <li>Jamstack + Headless CMS</li>
                </ul>
              </div>
            </div>
          </ParallaxElement>
        </ParallaxClipPath>
      </section>
    </Layout>
  )
}

export default IndexPage
