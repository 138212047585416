/**
 * parallaxElement Version: 1.0
 * 
 * Parallax effect for html element
 *
 * by Lance Oliver Cummings, lance@glance.ca
 * 
 * Changelog:
 * v 1.0, 29.01.2020
 * 
 */

import React, { useState, useRef } from 'react'
import { useScrollPosition } from "../../node_modules/@lancecummings/use-scroll-position"
import { useCssBreakpoints } from "../../node_modules/@lancecummings/css-breakpoints"

function ParallaxElement({
  children,
  className = "",
  inertia = -0.1,
  property = "translateY",
  unit = "px",
  breakpointsToIgnore = ["small"],
}) {
  const elementRef = useRef()
  const [elementPosition, setElementPosition] = useState({
    transform: property + '(0)'
  })
  const [winScroll, setWinScroll] = useState(0)
  const [breakpoint, setBreakpoint] = useState("")

  const shouldIgnore = () => {
    let ignore = false
    breakpointsToIgnore.forEach(function(el, i) {
      if (el === breakpoint) {
        ignore = true
      }
    })
    return ignore
  }

  function update() {
    if (shouldIgnore()) {
      setElementPosition({})
      return
    }

    const elOffset = elementRef.current.getBoundingClientRect()
    const pos = Math.round(elOffset.top * inertia)
    
    const elementUpdate = {
      transform: property + '(' + pos + unit + ')'
    }

    if (JSON.stringify(elementUpdate) === JSON.stringify(elementPosition))
      return

    setElementPosition(elementUpdate)
  }

  useCssBreakpoints(
    ({ prevBreakpoint, breakpoint }) => {
      setBreakpoint(breakpoint)
      update()
    },
    [breakpoint],
    100
  )

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (JSON.stringify(winScroll) === JSON.stringify(currPos.y)) return
      setWinScroll(currPos.y)
      update()
    },
    [elementPosition],
    false,
    false,
    1
  )

  const Content = (
    <div ref={elementRef} className={className} style={elementPosition}>
      {children}
    </div>
  )

  return Content
}

export default ParallaxElement