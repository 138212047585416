/**
 * parallaxClipPath Version: 1.0
 * 
 * Parallax effect CSS Clipping Paths
 *
 * by Lance Oliver Cummings, lance@glance.ca
 * 
 * Changelog:
 * v 1.0, 29.01.2020
 * 
 */

import React, { useState, useRef } from 'react'
import { useScrollPosition } from "../../node_modules/@lancecummings/use-scroll-position"
import { useCssBreakpoints } from "../../node_modules/@lancecummings/css-breakpoints"

function ParallaxClipPath({
  children,
  className = "panel",
  xPerc = 84,
  inertia = 0.02,
  breakpointsToIgnore = ["small"],
  direction = "right",
  inverted = "false",
}) {
  const elementRef = useRef()
  let path =
    inverted === "true"
      ? "polygon(" + xPerc + "% 10vw, 100% 0, 100% 100%, 0 100%, 0 0)"
      : "polygon(" + xPerc + "% 0, 100% 6vw, 100% 100%, 0 100%, 0% 6vw)"
  const [clipPathPosition, setClipPathPosition] = useState({
    WebkitClipPath: path,
    clipPath: path,
  })
  const [breakpoint, setBreakpoint] = useState("")

  const shouldIgnore = () => {
    let ignore = false
    breakpointsToIgnore.forEach(function(el, i) {
      if (el === breakpoint) {
        ignore = true
      }
    })
    return ignore
  }

  function update() {

    if (shouldIgnore()) {
      setClipPathPosition({})
      return
    }

    const vHeight = window.innerHeight
    let elOffset = elementRef.current.getBoundingClientRect()
    elOffset = elOffset.top
    if (elOffset < 0) {
      elOffset = 0
    }
    if (elOffset > vHeight) {
      elOffset = vHeight
    }
    const focus = vHeight * 0.75
    const offset =
      direction === "left"
        ? xPerc - (elOffset - focus) * inertia
        : xPerc + (elOffset - focus) * inertia
    path =
      inverted === "true"
        ? "polygon(" + offset + "% 10vw, 100% 0, 100% 100%, 0 100%, 0 0)"
        : "polygon(" + offset + "% 0, 100% 6vw, 100% 100%, 0 100%, 0% 6vw)"

    const clipPathUpdate = {
      WebkitClipPath: path,
      clipPath: path,
    }

    if (JSON.stringify(clipPathUpdate) === JSON.stringify(clipPathPosition))
      return

    setClipPathPosition(clipPathUpdate)
  }

  useCssBreakpoints(
    ({ prevBreakpoint, breakpoint }) => {
      setBreakpoint(breakpoint)
      update()
    },
    [breakpoint],
    100
  )

  useScrollPosition(
    ({ prevPos, currPos }) => {
      update()
    },
    [clipPathPosition],
    false,
    false,
    10
  )

  const Content = (
    <div ref={elementRef} className={className} style={clipPathPosition}>
      {children}
    </div>
  )

  return Content
}

export default ParallaxClipPath